import { useState } from 'react';

import { api } from '../../../global/api';



import type { IGood, IGoodCard, IGoodCompare } from '../../../entities/products/model/IGood';
import type { FavoriteGood } from '../../../components/Profile/Favorite/Item';

import { metric } from 'features/metric';
import { addCartGood, addToCartSize } from 'src/store/goods';
import { useDispatch } from './useDispatch';
import { GoodsErrorsList } from 'entities/products/lib/checkProductToBuy';
import { IEcomProduct } from 'features/metric/lib/metricActions';


export const useCart = (good: IGoodCard | FavoriteGood | IGood | IGoodCompare) => {

  const { id, name, price, brand, parent_categories } = good

  const product: IEcomProduct = {
    id, name, price, brand, parent_categories
  }

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const deleteItem = async (quantity = 1) => {
    setIsLoading(true);

    try {
      const res = await api.removeGoodFromCart(good.id, {
        quantity,
        include: 'prices',
      });
      dispatch(addToCartSize(res.data.data.changed_quantity));
      metric.removeProduct({ quantity, product })
    } catch (error) {
      console.error(error);
      return {
        error: GoodsErrorsList.DELETE_ERROR,
        available: false,
      };
    } finally {
      setIsLoading(false);
    }
  };
  const addToCart = async ({ quantity = 1, withModal = true, withCartChange = true }: { quantity?: number, withModal?: boolean, withCartChange?: boolean }) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const response = await api.addGoodToCart(good.id, { quantity });
      withModal && dispatch(addCartGood({ good, quantity: response.data.data.changed_quantity }));
      withCartChange && dispatch(addToCartSize(response.data.data.changed_quantity))
      metric.addProduct({ product, quantity })
    } catch (error) {
      const status = error.response?.status;

      if (status === 404) {
        return {
          error: GoodsErrorsList.NOT_FOUND,
          available: false,
        };
      }

      if (status === 422) {
        return {
          error: GoodsErrorsList.IS_OVER,
          available: false,
        };
      }

      if (status >= 500 && status < 600) {
        return {
          error: GoodsErrorsList.SERVER,
          available: false,
        };
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { addToCart, isLoading, deleteItem, setIsLoading };
};
