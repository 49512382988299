import React, { FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import type { IVariantProduct } from 'entities/products';

import PlaceholderImage from 'assets/images/placeholder_small.png';

import styles from './styles.module.scss';

type Props = {
  active: boolean;
  item: IVariantProduct & { tooltipText: string };
};

const ProductOptionsImage: FC<Props> = ({ item, active = false }) => {
  return (
    <Link href={`/product/${item.slug}`} passHref legacyBehavior>
      <a className={`${styles.variant} ${active ? styles.variantActive : ''}`} title={item.slug}>
        <Image
          src={item.main_image?.variants?.small_webp?.url || PlaceholderImage}
          width={item.main_image?.variants?.small_webp?.meta?.width || 100}
          height={item.main_image?.variants?.small_webp?.meta?.height || 100}
          className={styles.variantImg}
          alt="link image"
        />
        <div className={styles.variantColor}>
          {item.tooltipText.length > 14 ? `${item.tooltipText.slice(0, 13)}...` : item.tooltipText}
        </div>
      </a>
    </Link>
  );
};

export { ProductOptionsImage };
