import React from 'react';

const AssemblyProfSvg = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.52907 21.4606C1.70475 21.6362 1.92688 21.7581 2.16938 21.8118C2.41189 21.8656 2.6647 21.8491 2.89814 21.7642L9.32447 19.4273C9.79126 19.2575 10.2968 19.2245 10.7817 19.3321C11.2666 19.4397 11.7107 19.6833 12.062 20.0345L15.524 23.4965L21.5033 13.5311L20.0345 12.062C19.6833 11.7108 19.4396 11.2666 19.3321 10.7817C19.2245 10.2968 19.2575 9.79128 19.4273 9.3245L21.7643 2.89816C21.8491 2.66475 21.8656 2.41198 21.8119 2.16952C21.7581 1.92705 21.6362 1.70497 21.4606 1.52936L20.3133 0.38205C20.1921 0.260895 20.0482 0.164798 19.8899 0.099246C19.7316 0.0336936 19.5619 -3.02756e-05 19.3905 2.03959e-08C19.2192 3.03163e-05 19.0495 0.0338144 18.8912 0.0994227C18.7329 0.165031 18.5891 0.261179 18.4679 0.382376L11.6765 7.17357C11.4318 7.41838 11.2944 7.75032 11.2944 8.09643C11.2944 8.44253 11.4318 8.77447 11.6765 9.01928L12.4886 9.83109C12.7332 10.0759 12.8707 10.4078 12.8707 10.7539C12.8707 11.1 12.7332 11.432 12.4886 11.6768L11.6765 12.4886C11.5553 12.6098 11.4115 12.706 11.2531 12.7716C11.0948 12.8372 10.9251 12.8709 10.7538 12.8709C10.5824 12.8709 10.4127 12.8372 10.2544 12.7716C10.0961 12.706 9.95222 12.6098 9.83107 12.4886L9.01893 11.6768C8.89778 11.5556 8.75394 11.4594 8.59562 11.3938C8.43731 11.3282 8.26761 11.2945 8.09624 11.2945C7.92487 11.2945 7.75518 11.3282 7.59686 11.3938C7.43854 11.4594 7.2947 11.5556 7.17355 11.6768L0.382088 18.4679C0.137433 18.7127 0 19.0447 0 19.3908C0 19.7369 0.137433 20.0688 0.382088 20.3136L1.52907 21.4606Z"
        fill="#AF1EBE"
      />
      <path d="M22.3405 30.3129L28.3201 20.3478L24.2406 16.2683L18.261 26.2334L22.3405 30.3129Z" fill="#AF1EBE" />
      <path
        d="M28.0275 36H34.695C35.0411 36 35.373 35.8625 35.6177 35.6177C35.8625 35.373 36 35.0411 36 34.695V28.0275L31.0571 23.0846L25.0778 33.0503L28.0275 36Z"
        fill="#AF1EBE"
      />
    </svg>
  );
};

export { AssemblyProfSvg };
