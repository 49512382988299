import { api } from 'global/api';

export const convertBreadcrumbs = (breadcrumbsData: { slug: string; title: string }[], i: number) =>
  breadcrumbsData?.slice(0, i + 1)?.reduce((acc, item) => acc + '/' + item.slug, '');

export const fetchBreadcrumbs = async (path: string) => {
  try {
    const breadcrumbsResp = await api.getBreadcrumbsByPath(path);
    const breadcrumbsData = breadcrumbsResp.data.data as { slug: string; title: string }[];
    const breadcrumbs = [
      {
        title: 'Каталог мебели',
        href: '/katalog',
      },
      ...breadcrumbsData.map((item, i) => {
        const slug = convertBreadcrumbs(breadcrumbsData, i);
        return {
          title: item.title,
          href: slug,
          clickableLast: true,
        };
      }),
    ];

    return breadcrumbs;
  } catch (error) {
    console.error(error);
  }
};
