import { FC, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';

import type { IGood } from 'entities/products/model/IGood';

import ModalLayout from '../../../../src/hoc/withModal';
import PlaceholderImage from 'assets/images/placeholder.png';
import { getImageVariantProps } from 'shared/lib/images';
import { useDispatch } from 'shared/lib/hooks/useDispatch';
import { useSelector } from 'shared/lib/hooks/useSelector';
import { closeCartModal } from 'src/store/goods';
import { checkProductToBuy, IProductError } from 'entities/products/lib/checkProductToBuy';

import { Button } from '@nebo-team-dev/vobaza.ui.button/dist';
import { Title } from '@nebo-team-dev/vobaza.ui.title/dist';
import styles from './styles.module.scss';

interface Props {
  onCloseErrorHandle?: () => void;
}

const CartModal: FC<Props> = () => {
  const [productError, setProductError] = useState<IProductError>(null);
  const cartGood = useSelector((state) => state.goods.cartGood) as IGood;

  const dispatch = useDispatch();
  const router = useRouter();

  const onClose = async () => {
    dispatch(closeCartModal());
  };

  const onCloseWithError = async () => {
    const errorRes = await checkProductToBuy(cartGood.id);

    if (errorRes) return setProductError(errorRes);

    dispatch(closeCartModal());
    router.push('/cart');
  };

  return (
    <>
      {cartGood && (
        <ModalLayout onClose={onClose} isWide={!productError}>
          {!productError ? (
            <div className={styles.inlineModalContent}>
              <Title element="h1" className={`${styles.inlineModalTitle} ${styles.wide}`}>
                Товар добавлен в корзину
              </Title>
              <div className={styles.favoriteModalItem}>
                <div className={styles.favoriteModalItemImage}>
                  {cartGood.images?.length || cartGood.main_image?.variants ? (
                    <Image
                      {...getImageVariantProps(
                        !!cartGood.images?.length ? cartGood.images[0].variants : cartGood.main_image.variants,
                        'medium'
                      )}
                      alt={cartGood.name}
                    />
                  ) : (
                    <Image src={PlaceholderImage} alt="" />
                  )}
                </div>
                <div className={styles.favoriteModalItemBlock}>
                  <div className={styles.favoriteModalItemInfo}>
                    <div onClick={onClose}>
                      <div className={styles.favoriteModalItemSku}>Артикул: {cartGood.sku}</div>
                      <Link
                        href={`/product/${cartGood.slug}`}
                        passHref
                        className={styles.favoriteModalItemTitle}
                        target="_blank"
                      >
                        {cartGood.seo?.page_name || cartGood.name}
                      </Link>
                    </div>
                    <div className={styles.favoriteModalItemPrice}>
                      <div className={styles.favoriteModalItemPriceBlock}>
                        {Intl.NumberFormat('ru-RU').format(cartGood.price)} ₽
                      </div>
                    </div>
                  </div>
                  <div className={styles.favoriteModalButtons}>
                    <Button variation="secondary" onClick={onClose} size="big" text="Продолжить покупки" isFullScreen />
                    <Button onClick={onCloseWithError} size="big" text="Перейти в корзину" isFullScreen />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.inlineModal} data-modal>
              <div className={styles.inlineModalContent}>
                <Title element="h2" className={styles.inlineModalTitle}>
                  {productError.title}
                </Title>
                <p className={styles.inlineModalText}>{productError.description}</p>
                <Button
                  className={styles.inlineModalItem}
                  text="Продолжить покупки"
                  size="big"
                  isFullScreen
                  onClick={onClose}
                />
              </div>
            </div>
          )}
        </ModalLayout>
      )}
    </>
  );
};
export { CartModal };
