import React from 'react';

const LiftSvg = () => {
  return (
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.09302 8.84613C4.73234 9.00683 4.5 9.36471 4.5 9.75957V13.614C4.5 14.338 5.2456 14.8221 5.90698 14.5274L17.5923 9.32097C17.8513 9.20556 18.1472 9.20555 18.4062 9.32096L30.0931 14.5275C30.7544 14.8221 31.5 14.3381 31.5 13.614V9.75959C31.5 9.36473 31.2676 9.00683 30.9069 8.84614L18.4062 3.27701C18.1472 3.16161 17.8513 3.16161 17.5923 3.27703L5.09302 8.84613Z"
        fill="#AF1EBE"
      />
      <path
        d="M5.09302 17.9047C4.73234 18.0654 4.5 18.4233 4.5 18.8182V22.6726C4.5 23.3966 5.2456 23.8807 5.90698 23.586L17.5923 18.3796C17.8513 18.2642 18.1472 18.2641 18.4062 18.3795L30.0931 23.5861C30.7544 23.8807 31.5 23.3967 31.5 22.6726V18.8182C31.5 18.4233 31.2676 18.0654 30.9069 17.9047L18.4062 12.3356C18.1472 12.2202 17.8513 12.2202 17.5923 12.3356L5.09302 17.9047Z"
        fill="#911BA0"
      />
      <path
        d="M5.09302 26.9633C4.73234 27.124 4.5 27.4819 4.5 27.8768V31.7312C4.5 32.4552 5.2456 32.9393 5.90698 32.6446L17.5923 27.4382C17.8513 27.3227 18.1472 27.3227 18.4062 27.4381L30.0931 32.6447C30.7544 32.9393 31.5 32.4553 31.5 31.7312V27.8768C31.5 27.4819 31.2676 27.124 30.9069 26.9633L18.4062 21.3942C18.1472 21.2788 17.8513 21.2788 17.5923 21.3942L5.09302 26.9633Z"
        fill="#77018D"
      />
    </svg>
  );
};

export { LiftSvg };
