import React, { FC, useEffect, useState } from 'react';
import Image from 'next/image';

import { ISticker } from 'src/models/ISticker';
import { IGoodCard } from 'entities/products/model/IGood';
import { isExpress } from 'entities/products/lib/isExpress';

import styles from './styles.module.scss';

type Props = {
  good?: IGoodCard;
  stickers?: ISticker[];
  newProposals?: boolean;
};

const CardStickers: FC<Props> = ({ good, stickers, newProposals }) => {
  const [stickersList, setStickersList] = useState<ISticker[]>([]);

  useEffect(() => {
    const goodsStickers = stickers
    ?.filter((sticker) => good.sticker_ids?.includes(sticker.id))
    .sort((a, b) => a.position - b.position);
    
    setStickersList(goodsStickers);
  }, []);
  
  const stickersView = stickersList?.slice(0, 3);
  
  return (
    <div className={`${styles.stickers}  ${(!isExpress(good) || newProposals) && styles.stickersNotExpress}`}>
      {stickersList?.length > 0 &&
        stickersView.map((sticker) =>
          sticker.content === 'TEXT' ? (
            <div
              className={`${styles.cardStickerText}`}
              style={{
                color: sticker.text_forecolor,
              }}
            >
              {sticker.name}
            </div>
          ) : (
            <Image
              key={sticker.id}
              src={sticker.url}
              alt={sticker.name}
              width={300}
              height={24}
              className={`${styles.imageStickerItem}`}
            />
          )
        )}
    </div>
  );
};

export default CardStickers;
