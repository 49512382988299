import Link from 'next/link';
import { ReactNode } from 'react';

import { Button } from '@nebo-team-dev/vobaza.ui.button/dist';
import { Icon } from '@nebo-team-dev/vobaza.ui.icon/dist';

import styles from 'global/styles/CheckoutComplete.module.scss';

interface IProps {
  children: ReactNode;
  oneClick?: boolean;
  onClick?: () => void;
}

const SuccessfulSend = ({ children, oneClick = false, onClick = () => {} }: IProps) => (
  <div>
    <div className="container">
      <div className={styles.checkoutComplete}>
        <div className={styles.checkoutCompleteIcon}>
          <Icon name="SmallLogo" />
        </div>
        {children}
        {oneClick ? (
          <Button
            className={styles.checkoutCompleteButton}
            text="Продолжить покупки"
            size="big"
            isFullScreen
            onClick={onClick}
          />
        ) : (
          <Link href="/" className={styles.checkoutCompleteButton}>
            <Button text="Вернуться на главную" size="big" isFullScreen />
          </Link>
        )}
      </div>
    </div>
  </div>
);

export default SuccessfulSend;
