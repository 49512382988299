import React from 'react';

export const ExpressMobileSVG = () => {
    return (
        <svg width="86" height="20" viewBox="0 0 86 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 6C8 2.68629 10.6863 0 14 0H74.2305C76.0723 0 77.8121 0.845927 78.9496 2.29454L83.0603 7.52969C84.1989 8.97976 84.1989 11.0202 83.0603 12.4703L78.9496 17.7055C77.8121 19.1541 76.0723 20 74.2305 20H14C10.6863 20 8 17.3137 8 14V6Z" fill="#D9D9D9"/>
            <path d="M8 6C8 2.68629 10.6863 0 14 0H74.2305C76.0723 0 77.8121 0.845927 78.9496 2.29454L83.0603 7.52969C84.1989 8.97976 84.1989 11.0202 83.0603 12.4703L78.9496 17.7055C77.8121 19.1541 76.0723 20 74.2305 20H14C10.6863 20 8 17.3137 8 14V6Z" fill="#CEEDF7"/>
            <path d="M4 6C4 2.68629 6.68629 0 10 0H70.2305C72.0723 0 73.8121 0.845927 74.9496 2.29454L79.0603 7.52969C80.1989 8.97976 80.1989 11.0202 79.0603 12.4703L74.9496 17.7055C73.8121 19.1541 72.0723 20 70.2305 20H10C6.68629 20 4 17.3137 4 14V6Z" fill="#D9D9D9"/>
            <path d="M4 6C4 2.68629 6.68629 0 10 0H70.2305C72.0723 0 73.8121 0.845927 74.9496 2.29454L79.0603 7.52969C80.1989 8.97976 80.1989 11.0202 79.0603 12.4703L74.9496 17.7055C73.8121 19.1541 72.0723 20 70.2305 20H10C6.68629 20 4 17.3137 4 14V6Z" fill="#68C9D7"/>
            <path d="M0 6C0 2.68629 2.68629 0 6 0H66.2305C68.0723 0 69.8121 0.845927 70.9496 2.29454L75.0603 7.52969C76.1989 8.97976 76.1989 11.0202 75.0603 12.4703L70.9496 17.7055C69.8121 19.1541 68.0723 20 66.2305 20H6C2.68629 20 0 17.3137 0 14V6Z" fill="#D9D9D9"/>
            <path d="M0 6C0 2.68629 2.68629 0 6 0H66.2305C68.0723 0 69.8121 0.845927 70.9496 2.29454L75.0603 7.52969C76.1989 8.97976 76.1989 11.0202 75.0603 12.4703L70.9496 17.7055C69.8121 19.1541 68.0723 20 66.2305 20H6C2.68629 20 0 17.3137 0 14V6Z" fill="#14B1B1"/>
        </svg>
    )
}