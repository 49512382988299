import {FastDeliverySVG} from 'assets/svg/FastDeliverySVG';
import React, { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useCart } from 'shared/lib/hooks/useCart';
import CartItemChangeModal from 'components/Cart/Modal/CartItemChangeModal';
import type { IGoodCard } from 'entities/products/model/IGood';
import { IProductError } from 'entities/products/lib/checkProductToBuy';
import { CardExpressSupplyDays } from '../CardSupplyDays/Express';
import { CardSupplyDays } from '../CardSupplyDays';
import { ExpressSVG } from '../../../../../assets/svg/ExpressSVG';
import { useSelector } from 'react-redux';
import type { RootState } from 'src/store';
import { useRouter } from 'next/router';
import styles from './styles.module.scss';
import { Button } from '@nebo-team-dev/vobaza.ui.button';

import { metric } from 'features/metric';

import { Counter } from 'widgets/Counter';
import {pluralizeNoun} from "../../../../../shared/lib/pluralizeNoun";
import {FastDeliveryMobileSVG} from "../../../../../assets/svg/FastDeliveryMobileSVG";
import {MAX_SUPPLY_DAYS} from "../../../../../shared/lib/constants";
import {ExpressMobileSVG} from "../../../../../assets/svg/ExpressMobileSVG";

type Props = {
  good?: IGoodCard;
  onError?: (productError: IProductError) => void;
};

const CardButtons: FC<Props> = ({ good }) => {
  const basketItems = useSelector((store: RootState) => store.goods.basketItems);

  const [errorToBuy, setErrorToBuy] = useState<IProductError | null>(null);
  const [quantity, setQuantity] = useState<number>(0);
  const { addToCart, isLoading, deleteItem } = useCart(good);
  const router = useRouter();
  const isFastDelivery = router.asPath.includes('/fast-delivery');
  const addToCartHandler = async () => {
    const res = await addToCart({ withModal: false });
    if (!res?.error) {
      setQuantity((oldValue) => oldValue + 1);
      metric.reachGoal('basket');
    }
    if (res?.error) setErrorToBuy(res?.error);
  };
  const decreaseHandler = async () => {
    if (quantity <= 0) return;
    const res = await deleteItem();
    if (!res?.error) {
      setQuantity((oldValue) => oldValue - 1);
      metric.reachGoal('basket');
    }
    res?.error && setErrorToBuy(res?.error);
  };
  useEffect(() => {
    const goodInCartQuantity = basketItems.find((item) => item.id === good.id)?.quantity;
    setQuantity(goodInCartQuantity || 0);
  }, [basketItems]);
  return (
    <>
      <div className={`${styles.cardCart} `}>
        {good?.express && !isFastDelivery && (
          <div>
            <div className={styles.deliveryContent}>
              <div className={styles.mobileHidden}>
                <ExpressSVG />
              </div>
              <div className={styles.deliveryMobile}>
                <ExpressMobileSVG />
              </div>
              <span className={styles.deliveryText}>Экспресс</span>
            </div>
            <p className={styles.expressText}>
              Доставим
              <CardExpressSupplyDays good={good} />
            </p>
          </div>
        )}
        {Number(good?.expected_supply_days) <= MAX_SUPPLY_DAYS && (isFastDelivery || !good?.express) && (
            <div>
              <div className={styles.deliveryContent}>
                <div className={styles.mobileHidden}>
                  <FastDeliverySVG />
                  <span className={styles.deliveryText}>Доставим за {good.expected_supply_days} {pluralizeNoun(Number(good.expected_supply_days), ['день', 'дня', 'дней'])}</span>
                </div>
                <div className={styles.deliveryMobile}>
                  <FastDeliveryMobileSVG />
                  <span className={styles.deliveryText}>За {good.expected_supply_days} {pluralizeNoun(Number(good.expected_supply_days), ['день', 'дня', 'дней'])}</span>
                </div>
              </div>
            </div>
        )}
        {(!good.express || isFastDelivery) && (
            <div className={styles.deliveryInfoText}>
              <CardSupplyDays good={good} isFastDelivery={isFastDelivery} />
            </div>
        )}
      </div>
      <div className={styles.buttonsWrapper}>
        {Boolean(quantity) ? (
            <Counter
            className={styles.productCardCounter}
            value={quantity}
            increaseHandler={addToCartHandler}
            decreaseHandler={decreaseHandler}
            isLoading={isLoading}
          />
        ) : (
          <Button
            disabled={isLoading}
            onClick={
              Boolean(quantity)
                ? () => {
                    router.push('/cart');
                  }
                : addToCartHandler
            }
            text="В корзину"
          />
        )}
      </div>

      {errorToBuy &&
        createPortal(
          <CartItemChangeModal
            title={errorToBuy.title || ''}
            description={errorToBuy.description || ''}
            onClose={() => {
              setErrorToBuy(null);
            }}
          />,
          document.body
        )}
    </>
  );
};

export default CardButtons;
