import { FC, HTMLAttributes, SetStateAction, Dispatch, CSSProperties } from 'react';

import { Button } from '@nebo-team-dev/vobaza.ui.button';

import styles from './styles.module.scss';

interface CounterProps extends HTMLAttributes<HTMLDivElement> {
  decreaseHandler?: () => void;
  increaseHandler?: () => void;
  setValue?: Dispatch<SetStateAction<number>>;
  value: number;
  isLoading?: boolean;
  isOneDisable?: boolean;
  style?: CSSProperties;
  variant?: 'primary' | 'secondary';
}

export const Counter: FC<CounterProps> = ({
  setValue,
  /**
   * Значение счетчика
   */
  value,
  /**
   * Флаг загрузки
   */
  isLoading,
  /**
   * Обработчик срабатывающий при увеличении счетчика
   */
  increaseHandler,
  /**
   * Обработчик срабатывающий при уменьшении счетчика
   */
  decreaseHandler,
  /**
   * Имя класса контейнера счетчика
   */
  className,
  /**
   * Флаг который регулирует возможность уменьшать значение меньше одного
   */
  isOneDisable,
  /**
   * ОбъектСтилей
   */
  style,
  /**
   * вариация цветовой палитры
   */
  variant,
}) => {
  const buttonsClassName = variant === 'secondary' ? styles.counter__button_grey : '';
  const minusHandler = () => {
    value > 1 && setValue?.((oldValue) => oldValue - 1);
    decreaseHandler?.();
  };

  const plusHandler = () => {
    setValue?.((oldValue) => oldValue + 1);
    increaseHandler?.();
  };
  return (
    <div style={style} className={`${className} ${styles.counter} `}>
      <Button
        className={`${buttonsClassName} ${(isOneDisable && value === 1) || isLoading ? styles.disabled : ''}`}
        icon="Minus"
        onClick={minusHandler}
      />
      <span>{value}</span>
      <Button className={`${buttonsClassName} ${isLoading ? styles.disabled : ''}`} icon="Plus" onClick={plusHandler} />
    </div>
  );
};
