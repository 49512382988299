import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { FormikErrors, useFormik } from 'formik';
import Image from 'next/image';
import { useRouter } from 'next/router';
import * as yup from 'yup';
import Link from 'next/link';

import type { IError } from 'src/models/IError';
import type { IProfile } from 'app/profile/Data';
import { getImageVariantProps } from 'shared/lib/images';
import { useDispatch } from 'shared/lib/hooks/useDispatch';
import { useSelector } from 'shared/lib/hooks/useSelector';
import { metric } from 'features/metric';
import { closeOneClickModal } from 'src/store/goods';

import { Title } from '@nebo-team-dev/vobaza.ui.title/dist';
import { Button } from '@nebo-team-dev/vobaza.ui.button/dist';
import { InputText } from '@nebo-team-dev/vobaza.ui.inputs.input-text/dist';
import { InputPhone } from '@nebo-team-dev/vobaza.ui.inputs.input-phone/dist';
import SuccessfulSend from 'shared/ui/SuccessfulSend';
import ModalLayout from 'src/hoc/withModal';
import { InvisibleCaptcha } from 'components/Captcha';

import PlaceholderImage from 'assets/images/placeholder.png';

import styles from './styles.module.scss';
import { api } from 'global/api';
import { IProductError, GoodsErrorsList } from 'entities/products/lib/checkProductToBuy';

const needCaptcha = process.env.NEXT_PUBLIC_NEED_CAPTCHA === 'true';
const CAPTCHA_TOKEN_TEST = 'captcha-token-test';

interface IOneClickOrder {
  name: string;
  phone: string;
  email: string;
}

const getInitialValues = (user: IProfile | null) => ({
  name: user?.name || '',
  phone: user?.phone || '',
  email: user?.email || '',
});

const validationSchema = yup.object({
  name: yup.string(),
  phone: yup.string().min(12, 'Номер должен содержать минимум 11 цифр').required('Обязательное поле'),
  email: yup.string().email('Невалидный адрес электронной почты'),
});

type Props = {
  /** Обработчик ошибки при попытке создания заказа */
  onError?: (productId: number | string, errors: IError[], error: IProductError | null) => void;
  user: IProfile | null;
};

const OneClickModal: FC<Props> = ({ onError, user }) => {
  const router = useRouter();
  const product = useSelector((state) => state.goods.oneClickGood);
  const dispatch = useDispatch();
  const [orderNumber, setOrderNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessfulSubmit, setIsSuccessfulSubmit] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');
  const [visible, setVisible] = useState(false);

  const createOrder = async () => {
    if (needCaptcha && !captchaToken) return;

    setIsLoading(true);
    const ya_client_id = metric.getClientID();
    const captcha_token = needCaptcha ? captchaToken : CAPTCHA_TOKEN_TEST;

    const data = {
      name: values.name,
      phone: values.phone,
      ...(values.email && { email: values.email }),
      ya_client_id,
      captcha_token,
    };

    const category = product.parent_categories
      .map(({ name }) => {
        return name;
      })
      .join('/');

    const ecomGoods = [
      {
        id: product.id.toString(),
        name: product.name,
        price: product.price / 100,
        ...(product.brand ? { brand: product.brand } : {}),
        quantity: product.quantity,
        category,
      },
    ];

    try {
      const res = await api.createOneClickOrder(+product.id, data);

      if (res.data.data.id) metric.purchase(ecomGoods, res.data.data.number);

      if (res.data.data.number) setOrderNumber(res.data.data.number);

      setIsSuccessfulSubmit(true);

      metric.reachGoal('buy_with_one_click_form_sended');
    } catch (error) {
      const status = error.response?.status;
      const errors = error.response?.data?.errors || [];
      // GOOD ERRORS HANDLER
      if (status === 404) {
        onError && onError(product.id, errors, GoodsErrorsList.NOT_FOUND);
        return GoodsErrorsList.NOT_FOUND;
      }

      if (status === 422) {
        onError && onError(product.id, errors, GoodsErrorsList.IS_OVER);
        return GoodsErrorsList.IS_OVER;
      }

      if (status >= 500 && status < 600) {
        onError && onError(product.id, errors, GoodsErrorsList.SERVER);
        return GoodsErrorsList.SERVER;
      }

      onError && onError(product.id, errors, null);

      const backErrors = {} as FormikErrors<IOneClickOrder>;
      errors.forEach((err: IError) => {
        if (err.source) {
          backErrors[err.source] = err.title;
        }
      });
      setErrors(backErrors);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const { values, errors, validateField, setFieldValue, handleSubmit, resetForm, setErrors } =
    useFormik<IOneClickOrder>({
      initialValues: getInitialValues(user),
      validationSchema,
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: createOrder,
    });

  const handleSubmitForm = () => {
    if (needCaptcha && !captchaToken) setVisible(true);

    handleSubmit();
  };

  const onClose = () => {
    if (!isLoading) {
      dispatch(closeOneClickModal());
      setIsSuccessfulSubmit(false);
    }
  };

  const handleBlur = async (e: FocusEvent<HTMLInputElement>) => {
    const fieldName = e.target.name === 'oneClickName' ? 'name' : e.target.name;

    validateField(fieldName);
  };

  // Handle fields changing
  const handlePhoneChange = async (value: string) => {
    await setFieldValue('phone', value);
  };
  const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name === 'oneClickName' ? 'name' : e.target.name;

    await setFieldValue(fieldName, e.target.value);
  };

  useEffect(() => {
    resetForm();
    setErrors({});
  }, [product]);

  // Чтобы окно не оставалось открытым при открытии другой ссылки,
  // когда открыто окно "Заказать в один клик"
  useEffect(() => {
    dispatch(closeOneClickModal());
  }, [router.asPath]);

  useEffect(() => {
    if (captchaToken && values.phone) handleSubmit();
  }, [captchaToken]);

  if (product)
    return (
      <ModalLayout onClose={onClose} isWide className={`${isSuccessfulSubmit ? styles.successModal : ''}`}>
        <div
          className={`${styles.contentWrapper} ${isSuccessfulSubmit ? styles.successWrapper : ''} ${
            isLoading ? styles.modalLoading : ''
          }`}
        >
          <InvisibleCaptcha setToken={setCaptchaToken} visible={visible} setVisible={setVisible} />
          {isSuccessfulSubmit ? (
            <>
              <SuccessfulSend oneClick onClick={onClose}>
                <h1 className={styles.checkoutCompleteTitle}>Спасибо за заказ!</h1>
                <div className={styles.checkoutCompleteText}>
                  Ваш заказ &#8470;&nbsp;{orderNumber} успешно оформлен. Скоро с вами свяжется менеджер для
                  подтверждения
                </div>
              </SuccessfulSend>
            </>
          ) : (
            <>
              <Title element="h1" className={`${styles.inlineModalTitle} ${styles.wide}`}>
                Заказать в 1 клик
              </Title>
              <div className={styles.modalItem}>
                <div className={styles.content}>
                  <div className={styles.imageBlock}>
                    <div className={styles.imageWrapper}>
                      {product.images || product.main_image ? (
                        product.images ? (
                          <Image
                            {...getImageVariantProps(product.images[0].variants, 'medium')}
                            width={175}
                            height={175}
                            alt={product.name}
                          />
                        ) : (
                          <Image
                            {...getImageVariantProps(product.main_image.variants, 'medium')}
                            width={175}
                            height={175}
                            alt={product.name}
                          />
                        )
                      ) : (
                        <Image
                          src={PlaceholderImage}
                          width={175}
                          height={175}
                          className={styles.placeholder}
                          alt="PlaceholderImage"
                        />
                      )}
                    </div>
                  </div>
                  <div className={styles.info}>
                    <div className={styles.productNameWrapper}>
                      <span className={styles.productId}>Артикул: {product.sku}</span>
                      <div className={styles.productName}>{product.name}</div>
                    </div>
                    <div className={styles.productPriceWrapper}>
                      {Intl.NumberFormat('ru-RU').format(product.price)}&nbsp;₽
                      {product.list_price && (
                        <div className={styles.productDiscountPrice}>
                          {Intl.NumberFormat('ru-RU').format(product.list_price)}
                          &nbsp;₽
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.modalItemBlock}>
                <form className={styles.form}>
                  <div className={styles.formItemWrapper}>
                    <div className={styles.formItem}>
                      <InputText
                        label="Имя"
                        name="oneClickName"
                        value={values.name}
                        error={errors.name}
                        onBlur={handleBlur}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                  <div className={styles.formItemWrapper}>
                    <div className={styles.formHalfItem}>
                      <InputPhone
                        name="phone"
                        value={values.phone}
                        error={errors.phone}
                        onChange={handlePhoneChange}
                        required
                      />
                    </div>
                    <div className={styles.formHalfItem}>
                      <InputText
                        label="Электронная почта"
                        name="email"
                        value={values.email}
                        error={errors.email}
                        onBlur={handleBlur}
                        onChange={handleOnChange}
                      />
                    </div>
                  </div>
                  <div className={styles.formItemWrapper}>
                    <div className={styles.contentFooter}>
                      <div className={styles.buttonWrapper}>
                        <Button size="big" text="Заказать" onClick={handleSubmitForm} isFullScreen />
                      </div>
                      <div className={styles.textWrapper}>
                        <div>
                          Нажимая «Заказать», вы соглашаетесь с{' '}
                          <Link href="/politika-obrabotki-dannyh" target="_blank" className={styles.link}>
                            договором оферты{' '}
                          </Link>{' '}
                          и подтверждаете своё согласие на обработку персональных данных
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </ModalLayout>
    );

  return <div />;
};

export { OneClickModal };
