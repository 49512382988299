import React from 'react';

const AssemblySvg = () => {
  return (
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 36.1836C27.9411 36.1836 36 28.1247 36 18.1836C36 8.24247 27.9411 0.183594 18 0.183594C8.05887 0.183594 0 8.24247 0 18.1836C0 21.7403 1.03158 25.0561 2.81186 27.8481L9.90259 20.7573C9.29861 18.9454 8.81542 14.5967 11.7145 11.6976C14.6137 8.79845 18.9623 9.28163 20.7743 9.88562L15.1682 15.4917C14.7777 15.8822 14.7777 16.5154 15.1682 16.9059L19.1898 20.9276C19.5803 21.3181 20.2135 21.3181 20.604 20.9276L26.2101 15.3215C26.8141 17.1334 27.2973 21.4821 24.3982 24.3812C21.4991 27.2803 17.1504 26.7971 15.3384 26.1932L8.22859 33.303C11.0427 35.1254 14.3978 36.1836 18 36.1836Z"
        fill="#77018D"
      />
    </svg>
  );
};

export { AssemblySvg };
