import React, { FC, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { NextRouter, useRouter } from 'next/router';
import type { IGoodCard } from 'entities/products/model/IGood';
import type { ISticker } from 'src/models/ISticker';
import { useFavorite } from 'shared/lib/hooks/useFavorite';
import { getImageVariantProps } from 'shared/lib/images';
import { toNumberWithSpaces } from 'shared/lib/formatters';
import { metric } from 'features/metric';
import CardProductVariants from './ProductVariants/CardProductVariants';
import CardButtons from './CardButtons/CardButtons';
import CardStickers from './CardStickers/CardStickers';
import MobileStickers from './CardStickers/MobileSrickers';
import { IProductError } from 'entities/products/lib/checkProductToBuy';
import CardUSP from './CardUSP/CardUSP';
import { Icon } from '@nebo-team-dev/vobaza.ui.icon/dist';

import PlaceholderImage from 'assets/images/placeholder.png';
import PlaceholderImageSmall from 'assets/images/placeholder_small.png';

import styles from './styles.module.scss';

type Props = {
  good?: IGoodCard;
  stickers?: ISticker[];
  newProposals?: boolean;
  onError?: (productError: IProductError) => void;
};

export const GoodsCard: FC<Props> = React.memo(({ good, stickers, newProposals, onError }) => {
  const [currentImage, setCurrentImage] = useState(null);
  const { currentFavorite, toggleFavorite } = useFavorite(good);
  const router = useRouter();
  const resetImage = () => {
    setCurrentImage(null);
  };

  const productPath = `/product/${good.slug}`;
  const onProductClick = (event) => {
    event.preventDefault();
    const productWindow = window.open(productPath, '_blank');
    productWindow.myToBack = (newPageRouter: NextRouter) => {
      newPageRouter.push(router.asPath).then(() => {
        productWindow.scrollTo(0, window.pageYOffset);
      });
    };
  };
  const handleClickFavorite = () => {
    toggleFavorite();
    metric.reachGoal('wishlist');
  };

  const displayImageSize = !currentImage?.main_image && {
    height: good?.main_image?.variants?.medium_webp?.meta.height || '100%',
    width: good?.main_image?.variants?.medium_webp?.meta.width || '100%',
  };

  return (
    <div className={styles.cardContainer} onMouseLeave={resetImage}>
      <div className={styles.card}>
        <MobileStickers stickers={stickers} good={good}/>
        <div className={`${styles.cardHeader} ${newProposals && styles.newProposalsCardHeader}`}>
          <CardStickers stickers={stickers} good={good} newProposals={newProposals}/>
          <div
              className={`${styles.cardIcon} ${currentFavorite ? styles.active : ''} ${
                  newProposals && styles.iconNewProposals
              }`}
              onClick={currentFavorite ? toggleFavorite : handleClickFavorite}
          >
            <Icon name="Favorite"/>
          </div>
        </div>
        <div className={styles.imageWrapper}>
          <div className={`${styles.cardImage} card__image`}>
            <a href={productPath} onClick={onProductClick}>
              {good.main_image?.variants?.medium_webp ? (
                  <Image
                      {...getImageVariantProps(
                          currentImage ? currentImage.main_image?.variants : good.main_image?.variants,
                          'medium_webp'
                      )}
                      {...displayImageSize}
                      height={278}
                      width={278}
                      alt={good.name}
                  />
              ) : (
                  <Image src={PlaceholderImage} alt={good.name} fill/>
              )}
            </a>
            {Boolean(good.list_price) && (
                <div className={`${styles.cardSale} ${styles.cardSaleMobile}`}>
                  {Math.round((good.price / good.list_price) * 100 - 100).toString()}%
                </div>
            )}
          </div>
          {good.percent_off && (
              <div className={`${styles.cardSale} ${styles.cardSaleMobile}`}>
                {'-'}
                {good.percent_off}%
              </div>
          )}
          {good.percent_off && (
              <div className={styles.cardSale}>
                {'-'}
                {good.percent_off}
                {'%'}
              </div>
          )}
        </div>
        <div className={styles.cardVariants}>
          <div onClick={resetImage} className={`${styles.cardVariantImageWrapper} ${styles.cardVariant}`}>
            {good.main_image?.variants?.small_webp ? (
                <Image
                    {...getImageVariantProps(good.main_image.variants, 'small_webp')}
                    alt={good.name}
                    className={styles.variantImg}
                />
            ) : (
                <Image src={PlaceholderImageSmall} className={styles.variantImg} alt="PlaceholderImage" fill/>
            )}
          </div>

          <CardProductVariants good={good} setCurrentImage={setCurrentImage}/>
        </div>
        <Link href={`/product/${good.slug}`} passHref className={styles.cardTitle} title={good.name} target="_blank">
          {good.seo?.page_name || good.name}
        </Link>
        <div className={styles.cardPriceBlock}>
          <div className={styles.cardPrice}>{toNumberWithSpaces(good.price)} ₽</div>
          {good.list_price && (
              <div className={`${styles.cardPrice} ${styles.cardPriceOld}`}>
                {toNumberWithSpaces(good.list_price)} ₽
              </div>
          )}
        </div>
        <div className={styles.cardUspWrapper}>
          <CardUSP good={good}/>
        </div>
        <CardButtons good={good} onError={onError}/>
      </div>
    </div>
  );
});
