import React from 'react';

const ExpressSVG = () => {
  return (
      <svg width="94" height="24" viewBox="0 0 94 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 6C8 2.68629 10.6863 0 14 0H81.9036C83.928 0 85.8158 1.02081 86.9243 2.71476L91.5669 9.80984C92.4374 11.1402 92.4374 12.8598 91.5669 14.1902L86.9243 21.2852C85.8158 22.9792 83.928 24 81.9036 24H14C10.6863 24 8 21.3137 8 18V6Z" fill="#D9D9D9"/>
          <path d="M8 6C8 2.68629 10.6863 0 14 0H81.9036C83.928 0 85.8158 1.02081 86.9243 2.71476L91.5669 9.80984C92.4374 11.1402 92.4374 12.8598 91.5669 14.1902L86.9243 21.2852C85.8158 22.9792 83.928 24 81.9036 24H14C10.6863 24 8 21.3137 8 18V6Z" fill="#CEEDF7"/>
          <path d="M4 6C4 2.68629 6.68629 0 10 0H77.9036C79.928 0 81.8158 1.02081 82.9243 2.71476L87.5669 9.80984C88.4374 11.1402 88.4374 12.8598 87.5669 14.1902L82.9243 21.2852C81.8158 22.9792 79.928 24 77.9036 24H9.99999C6.68628 24 4 21.3137 4 18V6Z" fill="#D9D9D9"/>
          <path d="M4 6C4 2.68629 6.68629 0 10 0H77.9036C79.928 0 81.8158 1.02081 82.9243 2.71476L87.5669 9.80984C88.4374 11.1402 88.4374 12.8598 87.5669 14.1902L82.9243 21.2852C81.8158 22.9792 79.928 24 77.9036 24H9.99999C6.68628 24 4 21.3137 4 18V6Z" fill="#68C9D7"/>
          <path d="M0 6C0 2.68629 2.68629 0 6 0H73.9036C75.928 0 77.8158 1.02081 78.9243 2.71476L83.5669 9.80984C84.4374 11.1402 84.4374 12.8598 83.5669 14.1902L78.9243 21.2852C77.8158 22.9792 75.928 24 73.9036 24H5.99999C2.68628 24 0 21.3137 0 18V6Z" fill="#D9D9D9"/>
          <path d="M0 6C0 2.68629 2.68629 0 6 0H73.9036C75.928 0 77.8158 1.02081 78.9243 2.71476L83.5669 9.80984C84.4374 11.1402 84.4374 12.8598 83.5669 14.1902L78.9243 21.2852C77.8158 22.9792 75.928 24 73.9036 24H5.99999C2.68628 24 0 21.3137 0 18V6Z" fill="#14B1B1"/>
      </svg>
  );
};

export { ExpressSVG };
