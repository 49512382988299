import React, { FC } from 'react';

import type { IGoodCard } from 'entities/products/model/IGood';

import styles from './styles.module.scss';

type Props = {
  good?: IGoodCard;
};

const CardExpressSupplyDays: FC<Props> = React.memo(({ good }) => {
  const findExpress = Object.keys(good.express || {})
    .filter((key) => good.express[key] === true)
    .toString();

  const getExpress = (findExpress) => {
    if (findExpress.includes('tomorrow')) {
      return 'завтра';
    }
    if (findExpress.includes('three_hours')) {
      return 'за 3 часа';
    }
    if (findExpress.includes('today')) {
      return 'сегодня';
    }
    if (findExpress.includes('monday')) {
      return 'в понедельник';
    }
  };

  return <span className={styles.expressSupplyDays}>&nbsp;{getExpress(findExpress)}</span>;
});

export { CardExpressSupplyDays };
