import React from 'react';

export const FastDeliverySVG = () => {
    return (
        <svg width="157" height="24" viewBox="0 0 157 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M73 6C73 2.68629 75.6863 0 79 0H145.975C148.011 0 149.908 1.03237 151.013 2.74196L155.595 9.82797C156.45 11.1498 156.45 12.8502 155.595 14.172L151.013 21.258C149.908 22.9676 148.011 24 145.975 24H79C75.6863 24 73 21.3137 73 18V6Z" fill="#D9D9D9"/>
            <path d="M73 6C73 2.68629 75.6863 0 79 0H145.975C148.011 0 149.908 1.03237 151.013 2.74196L155.595 9.82797C156.45 11.1498 156.45 12.8502 155.595 14.172L151.013 21.258C149.908 22.9676 148.011 24 145.975 24H79C75.6863 24 73 21.3137 73 18V6Z" fill="#B5D8F8"/>
            <path d="M69 6C69 2.68629 71.6863 0 75 0H141.975C144.011 0 145.908 1.03237 147.013 2.74196L151.595 9.82797C152.45 11.1498 152.45 12.8502 151.595 14.172L147.013 21.258C145.908 22.9676 144.011 24 141.975 24H75C71.6863 24 69 21.3137 69 18V6Z" fill="#D9D9D9"/>
            <path d="M69 6C69 2.68629 71.6863 0 75 0H141.975C144.011 0 145.908 1.03237 147.013 2.74196L151.595 9.82797C152.45 11.1498 152.45 12.8502 151.595 14.172L147.013 21.258C145.908 22.9676 144.011 24 141.975 24H75C71.6863 24 69 21.3137 69 18V6Z" fill="#60A8EB"/>
            <path d="M0 6C0 2.68629 2.68629 0 6 0H137.946C139.977 0 141.871 1.02766 142.977 2.73089L147.584 9.82059C148.445 11.1459 148.445 12.8541 147.584 14.1794L142.977 21.2691C141.871 22.9723 139.977 24 137.946 24H6.00001C2.6863 24 0 21.3137 0 18V6Z" fill="#D9D9D9"/>
            <path d="M0 6C0 2.68629 2.68629 0 6 0H137.946C139.977 0 141.871 1.02766 142.977 2.73089L147.584 9.82059C148.445 11.1459 148.445 12.8541 147.584 14.1794L142.977 21.2691C141.871 22.9723 139.977 24 137.946 24H6.00001C2.6863 24 0 21.3137 0 18V6Z" fill="#2E679D"/>
        </svg>

    )
}