
import type { IGood } from 'entities/products';


export type IEcomProduct = {
  id: string | number;
  name: string;
  price: number;
  brand?: string;
  parent_categories?: Array<{ id: number, name: string }>
};

type Product = IEcomProduct;
type ReachGoalTarget =
  | 'basket'
  | 'wishlist'
  | 'order'
  | 'buy_with_one_click_form_opened'
  | 'call_request'
  | 'order_send'
  | 'buy_with_one_click_form_sended';

/** Контроллер действий для метрики */

const makeCategory = (product: Product) => {
  const maxPathLength = 4
  const category = product?.parent_categories?.filter((path, index) => index < maxPathLength).map(({ name }) => name).join('/') || '';
  return category
}
export const metric = {
  /** Добавить продукт в корзину */
  addProduct: ({ product, quantity = 1 }: { product: Product, quantity?: number }) => {

    const category = makeCategory(product);
    window.dataLayer = window?.dataLayer || [];
    window?.dataLayer?.push({
      ecommerce: {
        currencyCode: 'RUB',
        add: {
          products: [
            {
              id: product.id,
              name: product.name,
              price: product.price,
              brand: product.brand || '',
              category,
              quantity,
            },
          ],
        },
      },
    });
  },
  /** Удалить продукт из корзины */
  removeProduct: ({ product, quantity = 1 }: { product: IEcomProduct, quantity?: number }) => {
    const category = makeCategory(product);
    window.dataLayer = window?.dataLayer || [];
    window?.dataLayer?.push({
      ecommerce: {
        currencyCode: 'RUB',
        remove: {
          products: [
            {
              id: product.id,
              name: product.name,
              brand: product.brand || '',
              category,
              quantity,
            },
          ],
        },
      },
    });
  },
  /** Оформление заказа */
  purchase: (products: IEcomProduct[], purchaseId: number | string) => {
    window.dataLayer = window?.dataLayer || [];

    window?.dataLayer?.push({
      ecommerce: {
        currencyCode: 'RUB',
        purchase: {
          actionField: { id: purchaseId.toString() },
          products: products.map((product => ({ ...product, price: product.price * 100 }))),
        },
      },
    });
  },
  /** Просмотр детальной информации о товаре */
  detail: (product: IGood) => {
    const category = makeCategory(product);

    window.dataLayer = window?.dataLayer || [];
    window?.dataLayer?.push({
      ecommerce: {
        currencyCode: 'RUB',
        detail: {
          products: [
            {
              id: product.id,
              name: product.name,
              price: product.price,
              brand: product.brand,
              category: category,
            },
          ],
        },
      },
    });
  },
  reachGoal: (targetName: ReachGoalTarget) => {

    window?.[process.env.NEXT_PUBLIC_YANDEX_METRIKA_ID]?.reachGoal(targetName);
  },

  getClientID: () => {

    return window?.[process.env.NEXT_PUBLIC_YANDEX_METRIKA_ID]?.getClientID((clientID) => clientID);
  }
};
