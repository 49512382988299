import React, { FC } from 'react';

import { IGoodCard } from 'entities/products/model/IGood';
import { AssemblySvg } from 'assets/svg/USPsvg/AssemblySvg';
import { AssemblyProfSvg } from 'assets/svg/USPsvg/AssemblyProfSvg';
import { LiftSvg } from 'assets/svg/USPsvg/LiftSvg';

import styles from './styles.module.scss';

type Props = {
  good?: IGoodCard;
};

const CardUSP: FC<Props> = ({ good }) => {
  return (
    <>
      {good?.assembly === 'SIMPLY' && (
        <div className={styles.CardUSPContainer}>
          <AssemblySvg />
          <span>Бесплатно соберем</span>
        </div>
      )}
      {good?.assembly === 'PROFESSIONAL' && (
        <div className={styles.CardUSPContainer}>
          <AssemblyProfSvg />
          <span>Организуем сборку</span>
        </div>
      )}
      <div className={styles.CardUSPContainer}>
        <LiftSvg />
        <span>Бесплатно поднимем</span>
      </div>
    </>
  );
};

export default CardUSP;
