import axios from 'axios';

import { normalizeProductAttributes, normalizeProductInfo } from 'shared/lib/normalizers/normalizeGoods';
import { getProductOptions } from 'features/product-variation/lib/getProductOptions';
import { getProductInitialOptions } from './getProductInitialOptions';
import { formatAxiosError } from 'shared/lib/formatAxiosError';
import { fetchBreadcrumbs } from 'shared/lib/fetchBreadcrumbs';
import checkAuth from 'global/api/auth';

import { api } from 'global/api';

/** Получение SSR-пропсов на детальной странице товаров */
export const getFullProductProps = async ({ res, query, req }) => {

  const slug = getProductSlug(query.slug as string);
  const encodedSlug = encodeURI(slug);
  let user = null;

  res.setHeader('Cache-Control', 'public, max-age=180, immutable');
  try {
    await checkAuth(req, true);

  } catch (e) {
    console.error(e)
  }
  try {

    const productRes = await api.getGoodBySlug(encodedSlug);

    let product = normalizeProductInfo(productRes.data.data);

    const [attributesRes, breadcrumbs, isLoggedIn] = await Promise.all([
      api.getGoodAttributes(product.id),
      fetchBreadcrumbs(`/product/${encodedSlug}`),
      checkAuth(req, false, true),
    ]);

    const attributes = normalizeProductAttributes(attributesRes.data.data);

    product = { ...product, attributes };

    // TODO Для интеграции нового эндпоинта

    // Опции вариаций товаров
    const selectedOptions = getProductInitialOptions(product.variants, product.id);
    const options = getProductOptions(product.id, product.variants, selectedOptions);

    if (isLoggedIn) {
      const propfileRes = await api.getProfile();

      user = propfileRes.data.data;
    }

    const stickers = productRes.data.meta?.stickers || [];

    return {
      props: { initialProduct: product, options, breadcrumbs, user, stickers },
    };
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const text = formatAxiosError(err);
      console.error(text);
    } else {
      console.error(err);
    }
    return {
      notFound: true,
    } as const;
  }
};

const getProductSlug = (slug: string): string | null => {
  const parseSlug = slug.replace('/ekspress-dostavka', '');

  return parseSlug || null;
};
