import { api } from "global/api"

type ErrorAlias = 'NOT_FOUND' | 'IS_OVER' | 'SERVER' | 'OVERFULL_CART' | 'COUNT_IS_CHANGED' | 'CART_IS_EMPTY' | 'DELETE_ERROR';
export interface IProductError {
  title: string;
  description: string;
  alias?: ErrorAlias
}

export const GoodsErrorsList: Record<ErrorAlias, IProductError> = {
  DELETE_ERROR: {
    title: 'Не удалось удалить товар из корзины',
    description: '',
    alias: 'DELETE_ERROR'
  },
  COUNT_IS_CHANGED: {
    title: 'Данный товар не доступен',
    description: '',
    alias: 'COUNT_IS_CHANGED'
  },
  OVERFULL_CART: {
    title: 'Количество товара ограничено.',
    description: 'Недостаточно данного товара для заказа. Количество было уменьшено до доступного.',
    alias: 'OVERFULL_CART'
  },
  NOT_FOUND: {
    title: 'Данный товар не доступен',
    description: 'Товар более не доступен, пожалуйста, выберите другой.',
    alias: 'NOT_FOUND'
  },

  IS_OVER: {
    title: 'Данный товар не доступен',
    description: 'Товар более не доступен, пожалуйста, выберите другой.',
    alias: 'IS_OVER'
  },

  CART_IS_EMPTY: {
    title: 'Корзина пуста',
    description: 'В корзине должен быть хотя бы один товар',
    alias: 'CART_IS_EMPTY'
  },

  SERVER: {
    title: 'Ошибка сервера',
    description: 'Произошла непредвиденная ошибка, попробуйте позже.',
    alias: 'SERVER'
  },
}

export const checkProductToBuy = async (id: string | number): Promise<IProductError | null> => {
  try {
    await api.checkGoodSale(id);
    return null
  } catch (error) {
    const status = error.response?.status;

    // GOOD ERRORS HANDLER
    if (status === 404) {
      return GoodsErrorsList.NOT_FOUND;
    }

    if (status === 422) {
      return GoodsErrorsList.IS_OVER;
    }

    if (status >= 500 && status < 600) {
      return GoodsErrorsList.SERVER;
    }
  }
}