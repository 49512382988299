import React, { FC, useEffect, useState } from 'react';
import Image from 'next/image';

import { ISticker } from 'src/models/ISticker';
import { IGoodCard } from 'entities/products/model/IGood';

import styles from './styles.module.scss';

type Props = {
  good?: IGoodCard;
  stickers?: ISticker[];
};

const MobileStickers: FC<Props> = ({ stickers, good }) => {
  const [stickersList, setStickersList] = useState<ISticker[]>([]);

  const stickersView = stickersList?.slice(0, 3);

  useEffect(() => {
    const goodsStickers = stickers
      ?.filter((sticker) => good.sticker_ids?.includes(sticker.id))
      .sort((a, b) => a.position - b.position);

    setStickersList(goodsStickers);
  }, []);

  return (
    <div className={`${styles.stickersMobile}`}>
      {stickersList?.length > 0 &&
        stickersView.map((sticker) =>
          sticker.content === 'TEXT' ? (
            <div
              className={`${styles.cardStickerText}`}
              style={{
                color: sticker.text_forecolor,
              }}
            >
              {sticker.name}
            </div>
          ) : (
            <Image
              key={sticker.id}
              src={sticker.url}
              alt={sticker.name}
              width={100}
              height={15}
              className={`${styles.imageStickerItem} ${styles.stickerImgNotExpress}`}
            />
          )
        )}
    </div>
  );
};

export default MobileStickers;
