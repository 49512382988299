import classNames from 'classnames';
import React, { FC } from 'react';

import type { IGoodCard } from 'entities/products/model/IGood';

import styles from './styles.module.scss';

type Props = {
  good?: IGoodCard;
  isFastDelivery?: boolean;
  className?: string;
};

const CardSupplyDays: FC<Props> = React.memo(({ good, isFastDelivery, className }) => {
  const expectedDays = good?.expected_supply_days;
  const today: Date = new Date();
  today.setDate(today.getDate() + Number(expectedDays));

  const options: Intl.DateTimeFormatOptions = {
    weekday: undefined,
    day: 'numeric',
    month: 'long',
    timeZone: 'Europe/Moscow',
  };

  const fullDate: string = today.toLocaleDateString('ru-RU', options);

  const firstLetter = fullDate[0].toUpperCase();
  const capitalizedData = firstLetter + fullDate.slice(1);

  return (
    <>
      {(!good?.express || isFastDelivery) && (
        <div className={classNames(styles.cardSupplyDays, className)}>
          <p>
            <span>
              Доставим <span className={styles.boldDateText}>с {capitalizedData}</span>
            </span>
          </p>
        </div>
      )}
    </>
  );
});

export { CardSupplyDays };
