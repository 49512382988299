import { useEffect, useState } from 'react';

import type { IGood, IGoodCard } from '../../../entities/products/model/IGood';
import type { IGoodInCart } from '../../../entities/products/model/IGood';
import { addFavorite, removeFavorite } from 'src/store/goods';
import { useSelector } from './useSelector';
import { useDispatch } from './useDispatch';

import { api } from '../../../global/api/index';
type GoodTypes = IGood | IGoodCard;
export const useFavorite = (good: GoodTypes | IGoodInCart) => {
  const [currentFavorite, setCurrentFavorite] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const favoriteIds = useSelector((state) => state.goods.favoriteIds);
  const dispatch = useDispatch();

  function isGood(good: GoodTypes | IGoodInCart): good is GoodTypes {
    return (good as GoodTypes).id !== undefined;
  }
  const id = isGood(good) ? good.id : good.product.id;

  useEffect(() => {
    if (favoriteIds && favoriteIds.includes(id)) {
      setCurrentFavorite(true);
    } else {
      setCurrentFavorite(false);
    }
  }, [favoriteIds, id]);

  const toggleFavorite = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      if (currentFavorite) {
        await api.deleteGoodFavorite(id);
        dispatch(removeFavorite(id));
      } else {
        await api.setGoodFavorite(id);
        dispatch(addFavorite(id));
      }
      setCurrentFavorite(!currentFavorite);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { currentFavorite, toggleFavorite, isLoading, setIsLoading };
};
