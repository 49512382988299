import React, { FC, useState } from 'react';

import type { IGoodCard } from '../../../../entities/products/model/IGood';
import type { IProductError } from 'entities/products/lib/checkProductToBuy';

import { ProductCard } from '../../../../entities/products';
import CartItemChangeModal from 'components/Cart/Modal/CartItemChangeModal';

import styles from './styles.module.scss';
import { ISticker } from 'src/models/ISticker';

type Props = {
  goods: IGoodCard[];
  stickers?: ISticker[];
  newProposals?: boolean;
};

const GoodsList: FC<Props> = ({ goods, stickers, newProposals }) => {
  const [errorToBuy, setErrorToBuy] = useState<IProductError | null>(null);

  const handleProductError = (productError: IProductError) => {
    setErrorToBuy(productError);
  };

  const handleCloseError = () => {
    setErrorToBuy(null);
  };
  return (
    <>
      {errorToBuy && (
        <CartItemChangeModal
          title={errorToBuy.title || ''}
          description={errorToBuy.description || ''}
          onClose={handleCloseError}
        />
      )}

      <div className={styles.goodsList}>
        {goods.map((good) => (
          <ProductCard
            key={good.id}
            good={good}
            stickers={stickers}
            newProposals={newProposals}
            onError={handleProductError}
          />
        ))}
      </div>
    </>
  );
};

export { GoodsList };
