import React, { FC, useEffect, useState } from 'react';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Swiper as ISwiper } from 'swiper/types';
import { Thumbs } from 'swiper';
import { useRouter } from 'next/router';
import Image from 'next/image';

import { IVariantProduct } from 'entities/products';
import { ProductOptionsImage } from 'features/product-variation/ui/ProductOptionsImage';

import PlaceholderImageFull from 'assets/images/placeholder.png';
import PlaceholderImage from 'assets/images/placeholder_small.png';

import styles from './styles.module.scss';

const thumbsBreakpoints = {
  1300: { slidesPerView: 7 },
  767: { slidesPerView: 4 },
  1550: { slidesPerView: 8 },
};

const renderEmptyPlaceholder = (isThumb?: boolean) => {
  return (
    <div className={styles.slideImage}>
      <Image src={isThumb ? PlaceholderImage : PlaceholderImageFull} alt="product image" />
    </div>
  );
};

type Props = {
  currentProductId?: string | number;
  items: (IVariantProduct & { tooltipText: string })[];
};

const CaruselOption: FC<Props> = ({ currentProductId, items }) => {
  const [mainSwiper, setMainSwiper] = useState<ISwiper | null>(null);

  const router = useRouter();

  const renderThumbImages = () => {
    return items.map((img) => (
      <SwiperSlide key={`thumb-slide-${img.id}`} className={styles.thumbsSlide}>
        <div className={`thumb-slide-image ${styles.thumbsSlideImage}`}>
          <ProductOptionsImage key={img.tooltipText} item={img} active={img.id == currentProductId} />
        </div>
      </SwiperSlide>
    ));
  };

  useEffect(() => {
    if (mainSwiper && !mainSwiper.destroyed) {
      mainSwiper.update();
    }
  }, [mainSwiper, router.asPath]);

  const moreOneImage = items.length > 1;

  return (
    <>
      <div className={`${styles.pagination}`} />
      <div className={`${'good-thumb-swiper'} ${styles.thumbsSwiper} ${moreOneImage ? styles.thumbsActive : ''}`}>
        {items.length > 0 ? (
          <Swiper
            className={styles.swiper}
            modules={[Thumbs]}
            watchSlidesProgress
            slidesPerView={4}
            speed={600}
            slideToClickedSlide
            breakpoints={thumbsBreakpoints}
            onSwiper={setMainSwiper}
          >
            {Boolean(items.length) && renderThumbImages()}
          </Swiper>
        ) : (
          renderEmptyPlaceholder()
        )}
      </div>
    </>
  );
};

export { CaruselOption };
