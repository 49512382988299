import { Dispatch, SetStateAction, useCallback } from 'react';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';

interface IProps {
  setToken: (token: string) => void;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export const InvisibleCaptcha = ({ setToken, visible, setVisible }: IProps) => {
  const yaCaptcha = process.env.NEXT_PUBLIC_YANDEX_CAPTCHA_KEY;

  const handleChallengeHidden = useCallback(() => {
    setVisible(false);
  }, []);

  const onSuccess = (token: string) => {
    setToken(token);
    setVisible(false);
  };

  return (
    <>
      <InvisibleSmartCaptcha
        sitekey={yaCaptcha}
        onSuccess={onSuccess}
        onChallengeHidden={handleChallengeHidden}
        visible={visible}
        language={'ru'}
        hideShield
      />
    </>
  );
};
